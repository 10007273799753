<script lang="ts">
	import arrowRight from "../../../../core/assets/icons/arrowRight.svg?raw";
	import Icon from "../Icon.svelte";
	import type { Media } from "../../../../core/schema/Media.js";
	import { lazyLoadVideo } from "./lazyLoadVideo.js";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";
	import { sortSourceSetQuery } from "../../utils/sortSourceSetQuery.js";
	import { parseSourceSet } from "../../utils/parseSourceSet.js";

	export let video: Media;
	export let width: number | undefined = undefined;
	export let height: number | undefined = undefined;
	export let loop = true;
	export let autoplay = true;
	export let controls = false;
	export let muted = false;
	export let playsinline = true;
	export let videoClass = "";
	export let icon = false;
	export let posterImage: string | undefined = undefined;
	export let bucketUrl: string;
	export let lazy = false;
	export let sourcesets: Partial<Record<SourceSetQuery, Media>> = {};
	export let disabled = false;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, Media][];

	export let element: HTMLVideoElement | null = null;
	let hideIcon = false;
</script>

<video
	bind:this={element}
	use:lazyLoadVideo={{ enabled: lazy, autoplay }}
	autoplay={autoplay && !lazy}
	{height}
	{width}
	{loop}
	{muted}
	{playsinline}
	controls={controls && (hideIcon || !icon) && !disabled}
	class={videoClass}
	poster={posterImage}
	preload={lazy ? "none" : undefined}
>
	{#each sortedSourcesets as [query, video]}
		<source src="{bucketUrl}/{video.path}#t=0.001" media={parseSourceSet(query)} />
	{/each}

	<source src="{bucketUrl}/{video.path}#t=0.001" type={video.type} />
</video>
{#if icon && !hideIcon && controls}
	<button
		class="absolute inset-0 flex items-center justify-center transition-opacity duration-500 hover:opacity-50"
		on:click={async () => {
			await element?.play();
			hideIcon = true;
		}}
		{disabled}
	>
		<Icon icon={arrowRight} class="h-32 w-32 text-white" />
	</button>
{/if}
